import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Template from '../templates/InstagramPage';

const query = graphql`
  query {
#    allInstaNode(limit: 12) {
#      edges {
#        node {
#          id
#          username
#          localFile {
#            childImageSharp {
#              fluid(maxWidth: 300, maxHeight: 300) {
#                ...GatsbyImageSharpFluid
#              }
#            }
#          }
#        }
#      }
#    }
    pageYaml(page: { eq: "instagram" }) {
      hero
      title
    }
  }
`;

const IndexPage = () => (
  <StaticQuery
    query={query}
    render={(data) => <Template pageData={data.pageYaml} photoData={null} />}
  />
);

export default IndexPage;
