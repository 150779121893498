// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import GatsbyImage from 'gatsby-image';

import Section from '../molecules/Section';
import PhotoFrame from '../molecules/PhotoFrame';
import OrnamentalButton from '../atoms/OrnamentalButton';

type Props = {
  title: String,
  photoData: {
    edges: {
      node: {
        id: String,
        localFile: {
          childImageSharp: {
            fluid: Object,
          },
        },
      },
    }[],
  },
};

const PhotoGallary = ({ title, photoData }: Props) => {
  const baseUrl = 'https://www.instagram.com';
  const urlToUser = `${baseUrl}/little_mei1`;

  return (
    <Section title={title}>
      <PhotoList>
        {photoData !== null && photoData.edges.map((edge) => {
          const url = `${baseUrl}/p/${edge.node.id}/`;

          return (
            <PhotoItem key={edge.node.id}>
              <PhotoFrame>
                <a href={url} target="_blank" rel="noreferrer">
                  <GatsbyImage fluid={edge.node.localFile.childImageSharp.fluid} alt="photo" />
                </a>
              </PhotoFrame>
            </PhotoItem>
          );
        })}
      </PhotoList>
      <ButtonLayout>
        <a href={urlToUser} target="_blank" rel="noreferrer">
          <OrnamentalButton text="他の写真を見る" />
        </a>
      </ButtonLayout>
    </Section>
  );
};

const PhotoList = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '2rem auto 0',
  maxWidth: 736,
});

const PhotoItem = styled.div((props) => ({
  marginBottom: 30,
  width: '100%',
  [props.theme.mediaQuery.minTablet]: {
    marginLeft: 15,
    marginRight: 15,
    width: 'calc(50% - 30px)',
  },
}));

const ButtonLayout = styled.div({
  textAlign: 'center',
});

export default PhotoGallary;
