// @flow strict
import React from 'react';

import Hero from '../atoms/Hero';
import PhotoGallary from '../organisms/PhotoGallary';

type Props = {
  pageData: {
    hero: String,
    title: String,
  },
  photoData: {
    edges: {
      node: {
        id: String,
        localFile: {
          childImageSharp: {
            fluid: Object,
          },
        },
      },
    },
  },
};

const IndexPage = ({ pageData, photoData }: Props) => (
  <div>
    <Hero color={pageData.hero} />
    <PhotoGallary title={pageData.title} photoData={photoData} />
  </div>
);

export default IndexPage;
